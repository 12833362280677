import propTypes from 'prop-types';
import { H4 } from '@kiper/ui';
import { Card, Img, Container, Button } from './styles';

function ProductCard({ title, link, iconUrl, redirectApplication }) {
  return (
    <Container>
      <Button color="link" onClick={() => redirectApplication(link)}>
        <Card>
          <H4>{title}</H4>
          <Img src={iconUrl} alt={title} />
        </Card>
      </Button>
    </Container>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  title: propTypes.string.isRequired,
  link: propTypes.string.isRequired,
  iconUrl: propTypes.string.isRequired,
  redirectApplication: propTypes.func.isRequired,
};
