import { createContext, useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { runtimeConfig } from '@kiper/fns';
import { getFireInstance } from '../firebase';

export const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ defaults, children }) => {
  const [flags, setFlags] = useState(defaults);
  const [loading, setLoading] = useState(true);

  const firebase = getFireInstance();
  const config = firebase.remoteConfig(firebase);

  useEffect(() => {
    config.settings.minimumFetchIntervalMillis = 1800000;
    config.defaultConfig = defaults;

    if (runtimeConfig.RAZZLE_ENV === 'production') {
      config
        .fetchAndActivate()
        .then(() => config.getAll())
        .then(remoteFlags => {
          const newFlags = {
            ...flags,
          };

          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(remoteFlags)) {
            newFlags[key] = JSON.parse(value.asString());
          }

          setFlags(newFlags);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ loading, flags, defaults }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = () => useContext(FeatureFlagContext);

export const DEFAULT_FLAGS = {
  condominium: {
    patrolEvents: {
      condominiums: [],
      partners: [],
      users: [],
    },
    generalSettings: {
      condominiums: [],
      partners: [],
      users: [],
    },
    forGuestsOnlyToggle: {
      users: [],
      condominiums: [],
      partners: [],
    },
    placeMapCoordinate: {
      users: [],
      condominiums: [],
      partners: [],
    },
    uploadMap: {
      users: [],
      condominiums: [],
      partners: [],
    },
    resyncCond: {
      users: [],
      condominium: [],
      partners: [],
    },
    accessPermission: {
      users: [],
      condominium: [],
      partners: [],
    },
    showCondoCameraV2: {
      users: [],
      condominium: [],
      partners: [],
    },
  },
  attendance: {
    eventActivity: {
      condominiums: [],
      partners: [],
      users: [],
    },
    eventAutomaticCatch: {
      condominiums: [],
      partners: [],
      users: [],
    },
    keepOpenWithDelay: {
      users: [],
      condominiums: [],
      partners: [],
    },
    condominiumMap: {
      users: [],
      condominiums: [],
      partners: [],
    },
    eventV2: {
      users: [],
      condominiums: [],
      partners: [],
    },
    condominiumRules: {
      users: [],
      condominiums: [],
      partners: [],
    },
    filterEvent: {
      users: [],
      condominiums: [],
      partners: [],
    },
    eventTriage: {
      users: [],
      condominiums: [],
      partners: [],
    },
    triageQuestions: {
      users: [],
      condominiums: [],
      partners: [],
    },
    triageFilterRules: {
      users: [],
      condominiums: [],
      partners: [],
    },
    untreatedEvent: {
      users: [],
      condominiums: [],
      partners: [],
    },
  },
  users: {
    form: {
      features: {
        virtualOpenDoor: {
          users: [],
          condominiums: [],
          partners: [],
        },
        virtualVehicleControl: {
          users: [],
          condominiums: [],
          partners: [],
        },
        invites: {
          users: [],
          condominiums: [],
          partners: [],
        },
        invitations: {
          users: [],
          condominiums: [],
          partners: [],
        },
        cameras: {
          users: [],
          condominiums: [],
          partners: [],
        },
        locker: {
          users: [],
          condominiums: [],
          partners: [],
        },
        mural: {
          users: [],
          condominiums: [],
          partners: [],
        },
        accessTimeline: {
          users: [],
          condominiums: [],
          partners: [],
        },
        users: {
          users: [],
          condominiums: [],
          partners: [],
        },
      },
    },
    list: {},
  },
  eventSettings: {},
  inviteGuest: {},
};

FeatureFlagProvider.propTypes = {
  defaults: propTypes.object.isRequired,
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

FeatureFlagProvider.defaultProps = {
  children: undefined,
};
