import { useEffect } from 'react';
import { matchPath, useRouteMatch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from '@kiper/theme';
// import { GlobalStyle } from '@kiper/ui';
import { renderRoutes } from 'react-router-config';
import { Container } from 'reactstrap';
import propTypes from 'prop-types';

import GlobalStyle from '../../globalStyle';
import Header from '../Header';
import { initAmplitude } from '../../services/amplitude';

const isDevelopment = process.env.NODE_ENV === 'development';

const firebase = !isDevelopment ? require('../../services/firebase') : null;

const hideHeaderRoutes = [
  '/guest',
  '/activate',
  '/package-delivery',
  '/deposit-package',
];

const Root = ({ route, location }) => {
  const hideHeader = hideHeaderRoutes.some(hideHeaderRoute =>
    useRouteMatch(hideHeaderRoute),
  );

  initAmplitude();

  useEffect(() => {
    if (firebase) {
      const currentRoute = route.routes.find(routeObj =>
        matchPath(location.pathname, routeObj),
      );
      const firebaseInstance = firebase.getFireInstance();
      firebase.logEvent(firebaseInstance, 'page_view', {
        page_title: currentRoute && currentRoute.name,
        page_location: location.pathname,
      });
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {hideHeader ? (
        <main>{renderRoutes(route.routes)}</main>
      ) : (
        <Container>
          <Header />
          <main>{renderRoutes(route.routes)}</main>
        </Container>
      )}
    </ThemeProvider>
  );
};
export default Root;

Root.propTypes = {
  route: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
};
