import { useEffect, useMemo, useState, useContext } from 'react';
import { EmptyState, Button, GenericLoader } from '@kiper/ui';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/account-graphql/auth';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import authContext from '../../services/auth/context';
import ProductCard from '../../components/ProductCard';
import { Container, H3 } from './styles';
import emptyStatePlaceholder from '../../assets/images/not-allowed.svg';

function ProductHub({ location, history }) {
  const [t] = useTranslation('product_hub');
  const { signOut } = useContext(authContext);

  const { toast } = useSwal();

  const redirectApplication = url => {
    toast.fire({
      title: t('welcome'),
      icon: 'success',
    });
    setTimeout(() => {
      window.location = url;
    }, 1200);
  };

  const [state, setState] = useState(location.state);

  useEffect(() => {
    if (!state) history.push('/');
  }, []);

  const { data, loading } = useQuery(queries.applications, {
    skip: !state,
    variables: {
      personId: state && state.personId,
    },
  });

  const applications = useMemo(() => data && data.applications, [data]);

  useEffect(() => {
    if (applications && !!applications.length) {
      if (applications.length === 1)
        redirectApplication(applications[0].mainUrl);

      if (state.redirectUri) {
        if (applications.some(x => state.redirectUri.includes(x.mainUrl)))
          redirectApplication(state.redirectUri);
        else {
          setState({ redirectUri: null });
          toast.fire({
            title: t('errors.application-not-available'),
            icon: 'error',
          });
        }
      }
    }
  }, [applications]);

  if (
    loading ||
    (applications && applications.length === 1) ||
    (state && state.redirectUri)
  )
    return <GenericLoader />;

  return (
    <>
      {applications && !!applications.length && (
        <H3>{t('system-to-access')}</H3>
      )}
      <Container>
        {applications && !!applications.length ? (
          applications.map(x => (
            <ProductCard
              key={x.id}
              title={x.name}
              link={x.mainUrl}
              iconUrl={x.icon}
              redirectApplication={redirectApplication}
            />
          ))
        ) : (
          <EmptyState
            placeholder={emptyStatePlaceholder}
            text={t('empty-state.title')}
            message={t('empty-state.subtitle')}
          >
            <Button color="primary" onClick={signOut}>
              {t('logout')}
            </Button>
          </EmptyState>
        )}
      </Container>
    </>
  );
}

export default ProductHub;
ProductHub.propTypes = {
  location: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};
