import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdHelp, MdQrCode2 } from 'react-icons/md';
import { useMutation, useQuery } from 'react-apollo';
import { formatDate, compareDateFromNow } from '@kiper/fns/src/timezone';
import { apolloErrorHandler, getUserLang } from '@kiper/fns';
import { useSwal, useToggle, useQuery as useQueryParams } from '@kiper/hooks';
import { Modal, TabsMobile, Flex, GenericLoader } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { mutations, queries } from '@kiper/account-graphql/invite';
import InfoGuest from './InfoGuest';
import { inviteStatus } from '../../../constants';
import FacialRegisterButton from '../../../components/FacialRegisterButton';
import QrCode from '../../../components/QrCode';
import FacialInvitation from '../../../components/Modals/FacialInvitation/context/FacialInvitationProvider';
import {
  CalendarMonth,
  FamiliarFaceAndZoneIcon,
  HomePin,
} from '../../../components/SvgIcons';
import inviteGuestExpired from '../../../assets/images/invite-guest-expired.svg';
import inviteGuestInvalid from '../../../assets/images/invite-guest-invalid.svg';
import facialInitialPageIcon from '../../../assets/images/facial-initial-page-icon.svg';
import facialConfirmRegister from '../../../assets/images/facial-confirm-register.svg';
import logoKiper from '../../../assets/images/white-logo-kiper.svg';
import logoPorter from '../../../assets/images/white-logo-porter.svg';
import helpUseAppGif from '../../../assets/animations/helpUseAppGif.gif';
import * as S from './styles';
import {
  sendAmplitudeData,
  setAmplitudeUserProperties,
} from '../../../services/amplitude';

const concatenateAddress = ({
  unityGroupName,
  unityName,
  streetName,
  addressNumber,
}) => {
  let result = '';

  if (unityGroupName) result = result.concat(`${unityGroupName} - `);
  if (unityName) result = result.concat(`${unityName} - `);
  if (streetName) result = result ? result.concat(streetName) : streetName;
  if (addressNumber) result = result.concat(`, ${addressNumber}`);

  return result;
};

const TabsTypes = Object.freeze({
  FACIAL: 'Facial',
  QR_CODE: 'QR Code',
});

export default function InviteGuest() {
  const [t] = useTranslation('invite');
  const { toast } = useSwal();
  const [openModal, toggleModal] = useToggle(false);
  const querystring = useQueryParams();
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
  const guestHash = querystring.get('q').replace(' ', '+');

  const [activeTab, setActiveTab] = useState(0);
  const [hasFacialInvite, setHasFacialInvite] = useState(false);
  const [hasQrCodeInvite, setHasQrCodeInvite] = useState(false);
  const [toggleModalFaceCapture, setToggleModalFaceCapture] = useState(false);

  const tabs = [
    {
      title: TabsTypes.FACIAL,
      icon: (
        <FamiliarFaceAndZoneIcon
          color={activeTab === 0 ? 'white' : 'black'}
          size={20}
        />
      ),
    },
    {
      title: TabsTypes.QR_CODE,
      icon: <MdQrCode2 size={20} />,
    },
  ];

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const { data, loading, refetch } = useQuery(queries.get, {
    variables: { guestHash },
    onError: err => handleApolloError(err),
  });

  const facialTabSelected = useMemo(
    () => tabs[activeTab].title === TabsTypes.FACIAL,
    [activeTab],
  );

  const handleChangeTab = useCallback(
    tab => {
      const activeTabIndex = tabs.findIndex(
        tabItem => tabItem.title === tab.title,
      );
      setActiveTab(activeTabIndex);
    },
    [activeTab],
  );

  const language = useMemo(() => getUserLang().split('-')[0], []);

  const formatPass = useMemo(() => {
    let pass;
    const object = data?.getInviteGuest;
    if (data?.getInviteGuest?.hashId) {
      pass = {
        hashId: object.qrCode,
        guestId: object.id,
        inviteName: object.invite?.name,
        startText:
          object.invite.start &&
          formatDate(object.invite.start, 'L - HH:mm').formatted,
        start: object.invite?.start,
        end: object.invite?.end,
        condominiumName: object.condominium?.name || '',
        unityGroup: object.unityGroup?.name || '',
        unity: object.unity?.name || '',
        streetName: object.address?.streetName || '',
        addressNumber: object.address?.addressNumber || '',
        isPorterPartner: object.isPorterPartner,
        i18n: {
          language,
          title: t('pass.title'),
          subheader: t('pass.subheader'),
          date: t('pass.date'),
          local: t('pass.local'),
          address: t('pass.address'),
        },
      };
    }

    return pass;
  }, [data]);

  const [getPass, { loading: loadingPass }] = useMutation(
    mutations.createPass,
    {
      variables: { data: formatPass },
      onCompleted: res => {
        window.open(res?.getInvitePass?.hash, '_blank');
        sendAmplitudeData('get pass google wallet');
      },
      onError: err => handleApolloError(err),
    },
  );

  useEffect(() => {
    const enabledFacialInvite = data?.getInviteGuest?.hasFacial;
    const enabledQrCodeInvite = data?.getInviteGuest.hasQrCode;

    setHasFacialInvite(enabledFacialInvite);
    setHasQrCodeInvite(enabledQrCodeInvite);

    if (enabledFacialInvite) {
      setActiveTab(tabs.findIndex(tab => tab.title === TabsTypes.FACIAL));
    } else if (enabledQrCodeInvite) {
      setActiveTab(tabs.findIndex(tab => tab.title === TabsTypes.QR_CODE));
    }
  }, [data]);

  useEffect(
    () =>
      setAmplitudeUserProperties({
        condominium: data?.getInviteGuest?.condominiumPersonContextId,
        partner: data?.getInviteGuest?.partnerPersonContextId,
        condominiumName: data?.getInviteGuest?.condominium?.name,
        inviteName: data?.getInviteGuest?.invite?.name,
        hasFacial: data?.getInviteGuest?.hasFacial,
        hasQrCode: data?.getInviteGuest.hasQrCode,
      }),
    [data],
  );

  const formattedValues = useMemo(() => {
    if (data?.getInviteGuest?.hashId) {
      const {
        condominium,
        address: addressInvite,
        invite,
        unityGroup,
        unity,
        isPorterPartner,
        hasFaceId,
        qrCode,
        allowFaceEdit,
      } = data.getInviteGuest;

      const { name: condominiumName } = condominium;
      const { streetName, addressNumber } = addressInvite;
      const { start, end, name } = invite;
      const { name: unityGroupName } = unityGroup || { name: '' };
      const { name: unityName } = unity || { name: '' };

      return {
        hash: qrCode,
        allowFaceEdit,
        title: name,
        startInvite: start && formatDate(start, 'L - HH:mm').formatted,
        address: concatenateAddress({
          unityGroupName,
          unityName,
          streetName,
          addressNumber,
        }),
        addressLink: concatenateAddress({ streetName, addressNumber }),
        status:
          inviteStatus[compareDateFromNow(end).isAfter ? 'valid' : 'expired'],
        condominiumName,
        isPorterPartner,
        hasFaceId,
      };
    }
    return {
      status: inviteStatus.invalid,
      isPorterPartner: data?.getInviteGuest?.isPorterPartner,
    };
  }, [data]);

  const inviteValid = useMemo(
    () => formattedValues.hash && formattedValues.status === inviteStatus.valid,
    [formattedValues.hash, formattedValues.status],
  );

  const inviteExpired = useMemo(
    () => formattedValues.status === inviteStatus.expired,
    [formattedValues.status],
  );

  const inviteInvalid = useMemo(
    () => formattedValues.status === inviteStatus.invalid,
    [formattedValues.status],
  );

  const handleToggleModalFaceCapture = () =>
    setToggleModalFaceCapture(prevState => !prevState);

  const inviteRegisterStatusMessage = formattedValues.hasFaceId
    ? [
        {
          title: t('facial.confirm-register-title'),
          description: t('facial.confirm-register-message'),
          status: 'success',
          disabled: true,
          show: true,
        },
        {
          title: t('facial.update-button-title'),
          description: t('facial.update-button-message'),
          status: 'edit',
          disabled: false,
          show: formattedValues.allowFaceEdit,
        },
      ]
    : [
        {
          title: t('facial.register-button-title'),
          description: t('facial.register-button-message'),
          status: 'register',
          disabled: false,
          show: true,
        },
      ];

  const isIOS = useMemo(() => {
    const userAgent =
      navigator?.userAgent || navigator?.vendor || window?.opera;
    return /iPad|iPhone|iPod/i.test(userAgent);
  }, []);

  if (loading) return <GenericLoader />;

  const handleToggleModalHelpMe = () => {
    sendAmplitudeData('invite help me');
    toggleModal();
  };

  return (
    <>
      {toggleModalFaceCapture && (
        <FacialInvitation
          onToggle={() => setToggleModalFaceCapture(prevState => !prevState)}
          guestHash={guestHash}
          refetch={refetch}
        />
      )}
      {openModal && (
        <Modal
          open={openModal}
          toggle={toggleModal}
          size="sm"
          title={t('feedback.modal-title')}
        >
          <Flex alignItems="center" justifyContent="center">
            <img src={helpUseAppGif} alt="helpAppKiper" />
          </Flex>
        </Modal>
      )}

      {formattedValues && (
        <S.Wrapper isPorter={formattedValues.isPorterPartner}>
          <S.Content>
            <S.InviteHeader>
              <S.Img
                src={formattedValues.isPorterPartner ? logoPorter : logoKiper}
                alt="kiper"
              />
              <S.Button onClick={handleToggleModalHelpMe}>
                <MdHelp size={32} color="white" />
              </S.Button>
            </S.InviteHeader>
            {!(inviteExpired || inviteInvalid) &&
              hasFacialInvite &&
              hasQrCodeInvite && (
                <TabsMobile
                  tabs={tabs}
                  active={activeTab}
                  onChangeTab={handleChangeTab}
                />
              )}
            <S.InviteContainer>
              <S.Invite>
                {(inviteExpired || inviteInvalid) && (
                  <Flex alignItems="center" justifyContent="center">
                    <S.InviteInvalid
                      background={
                        inviteExpired ? inviteGuestExpired : inviteGuestInvalid
                      }
                    />
                  </Flex>
                )}
                {!(inviteExpired || inviteInvalid) &&
                  (facialTabSelected ? (
                    <S.FacialRegisterIconContainer>
                      <S.FacialRegisterIcon
                        icon={
                          formattedValues.hasFaceId
                            ? facialConfirmRegister
                            : facialInitialPageIcon
                        }
                      />
                    </S.FacialRegisterIconContainer>
                  ) : (
                    <S.QrCodeContainer>
                      <S.QrCodeArea>
                        {inviteValid && (
                          <QrCode
                            value={formattedValues.hash}
                            size={250}
                            marginSize={15}
                          />
                        )}
                      </S.QrCodeArea>
                    </S.QrCodeContainer>
                  ))}

                <S.InviteInfo>
                  {inviteExpired || inviteInvalid ? (
                    <>
                      <S.Span warning>
                        {t(`feedback.${inviteExpired ? 'expired' : 'invalid'}`)}
                      </S.Span>
                      <S.Span>
                        {t(
                          `feedback.${
                            inviteExpired
                              ? 'expired-context'
                              : 'invalid-context'
                          }`,
                        )}
                      </S.Span>
                    </>
                  ) : (
                    <>
                      {hasFacialInvite && (
                        <Flex
                          flexDirection="column"
                          alignItems="stretch"
                          gridGap="6px"
                        >
                          {inviteRegisterStatusMessage.map((item, key) => (
                            <FacialRegisterButton
                              {...item}
                              key={key}
                              onClick={() => handleToggleModalFaceCapture()}
                            />
                          ))}
                        </Flex>
                      )}
                      <div>
                        <S.InviteTitle expired={inviteExpired ? 1 : 0}>
                          {formattedValues.title}
                        </S.InviteTitle>
                        <InfoGuest
                          icon={<CalendarMonth color="#374AA0" size={32} />}
                          label={t('info.event.start')}
                          value={formattedValues.startInvite}
                          expired={inviteExpired}
                          isPorter={formattedValues.isPorterPartner}
                        />
                      </div>
                      {formattedValues.address && (
                        <InfoGuest
                          icon={<HomePin color="#374AA0" size={32} />}
                          label={t('info.event.place')}
                          value={formattedValues?.condominiumName}
                          expired={inviteExpired}
                          isPorter={formattedValues.isPorterPartner}
                          linkAddress={`${formattedValues?.addressLink}`}
                          address={`${formattedValues?.address}`}
                        />
                      )}
                    </>
                  )}
                </S.InviteInfo>
              </S.Invite>
            </S.InviteContainer>
            {!isIOS && !(inviteExpired || inviteInvalid) && (
              <S.GoogleWalletButton
                className={loadingPass && 'disabled'}
                onClick={() => getPass()}
                lang={language}
                isPorter={formattedValues.isPorterPartner}
              />
            )}
          </S.Content>
        </S.Wrapper>
      )}
    </>
  );
}
