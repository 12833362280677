import styled from 'styled-components';
import { EmptyState as emptyState } from '@kiper/ui';

import { H3 as h3 } from '@kiper/ui';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
`;

export const H3 = styled(h3)`
  margin-top: 20px;
`;

export const EmptyState = styled(emptyState)`
  max-width: 380px;
`;
