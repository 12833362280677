import styled from 'styled-components';
import { Button as button } from '@kiper/ui';

export const Container = styled.div`
  margin: 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.secondary300};
  width: 200px;
  height: 180px;
`;

export const Img = styled.img`
  width: 80px;
  align-items: center;
  margin: 0 auto;
`;

export const Button = styled(button)`
  padding: 0;
  border: 0;
`;
