import propTypes from 'prop-types';

const GlassesOff = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33405 0.990234L0.166992 2.15729L6.70021 8.69051C6.60521 8.96339 6.53252 9.17368 6.50199 9.26526L6.49807 9.29242C6.48785 9.36283 6.47866 9.42612 6.47866 9.49859C6.47866 10.2257 8.31824 12.017 11.2146 13.2049L14.7506 16.7409C9.84033 16.1243 5.87199 13.2528 5.87199 11.2836C5.87199 10.9686 5.94199 10.6653 6.05866 10.3853C3.99366 10.4786 1.33366 10.8519 1.33366 13.2086C1.33366 17.0819 10.4803 21.8303 17.7253 21.8303C18.4593 21.8303 19.1206 21.7864 19.7155 21.7058L23.3805 25.3708L24.6179 24.1333L24.6178 24.1333L24.5475 24.2036L1.33405 0.990234ZM23.2063 20.3876C24.3039 19.5045 24.667 18.3529 24.667 17.3386C24.667 15.7753 23.3253 14.0019 20.8987 12.9519C21.1087 13.9553 21.1087 14.0603 21.1087 14.1653C21.1087 15.2317 20.3895 15.9992 19.2547 16.436L23.2063 20.3876ZM17.1673 14.3486C18.6833 14.3109 20.6537 13.9322 20.6537 12.2053C20.6653 12.0653 20.6537 11.9253 20.6187 11.7853L19.7087 7.81859C19.4987 6.94359 19.312 6.54693 17.772 5.77693C16.582 5.17026 13.992 4.16693 13.222 4.16693C12.8821 4.16693 12.6567 4.37713 12.4211 4.59677C12.1634 4.83704 11.8936 5.08859 11.4487 5.08859C11.0343 5.08859 10.674 4.91141 10.3191 4.73683C9.97436 4.56732 9.63466 4.40026 9.25533 4.40026C8.72562 4.40026 8.32922 4.66082 8.00937 5.19068L17.1673 14.3486Z"
        fill={color}
      />
    </svg>
  );
};

GlassesOff.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};

GlassesOff.defaultProps = {
  size: 24,
  color: '#000',
};

export default GlassesOff;
