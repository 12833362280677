import { useContext, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import context from '../context';
import facialInitialPageIcon from '../../../../assets/images/facial-initial-page-icon.svg';
import * as S from './styles';

const prefix = 'face-invitation.terms-of-use';

const StepTermsOfUse = () => {
  const { handleChangeStep } = useContext(context);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const { t } = useTranslation('invite');

  return (
    <>
      <S.StepContainer>
        <S.FacialIconContainer>
          <S.FacialRegisterIcon icon={facialInitialPageIcon} />
        </S.FacialIconContainer>
        <S.Title>{t(`${prefix}.title`)}</S.Title>
        <Trans>
          <S.Description>{t(`${prefix}.description`)}</S.Description>
        </Trans>
        <S.CheckboxContainer>
          <S.Checkbox
            name="storePhoto"
            value={acceptTerms}
            id="checkbox-accept-terms-of-use"
            onChange={e => setAcceptTerms(e.target.checked)}
            checked={acceptTerms}
          />
          <S.LabelTerm htmlFor="checkbox-accept-terms-of-use">
            {t(`${prefix}.read-and-accept`)}
            <a
              href="https://portergroup.com.br/politica-de-privacidade/"
              target="_blank"
              rel="noreferrer"
            >
              {t(`${prefix}.terms`)}
            </a>
          </S.LabelTerm>
        </S.CheckboxContainer>
      </S.StepContainer>

      <S.Button
        variant="contained"
        color="porterPrimary"
        block
        centered
        disabled={!acceptTerms}
        onClick={() => handleChangeStep(1)}
      >
        {t(`${prefix}.continue`)}
      </S.Button>
    </>
  );
};

export default StepTermsOfUse;
