import styled from 'styled-components';
import { Label as label } from 'reactstrap';
import { darken } from 'polished';
import GWalletPT from '../../../assets/images/wallet-pt.png';
import GWalletEN from '../../../assets/images/wallet-en.png';
import GWalletES from '../../../assets/images/wallet-es.png';

const i18Styles = {
  pt: {
    button: GWalletPT,
    height: '40px',
  },
  en: {
    button: GWalletEN,
    height: '53px',
  },
  es: {
    button: GWalletES,
    height: '50px',
  },
};

const inviteBackgroundColor = (isPorter, theme) => ({
  primary: isPorter
    ? theme.colors.invitePorterPrimary
    : theme.colors.inviteKiperPrimary,
  secondary: isPorter
    ? theme.colors.invitePorterSecondary
    : theme.colors.inviteKiperSecondary,
});

export const Wrapper = styled.div`
  ${({ theme, isPorter }) => `
    background: ${inviteBackgroundColor(isPorter, theme).primary};
  `}
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Img = styled.img`
  height: 52px;
  width: auto;
`;

export const InviteHeader = styled.header`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 24px 8px 24px;
  gap: 36px;
`;

export const InviteContainer = styled.div`
  border-radius: 8px;
  background: #fff;
  width: 100%;
`;

export const Invite = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 8px;
  padding-bottom: 24px;
`;

export const QrCodeArea = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px;
`;

export const QrCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;

export const InviteInvalid = styled.div`
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: center;
  width: 200px;
  height: 200px;
  margin-bottom: 16px;
`;

export const InviteInfo = styled.div`
  display: flex;
  flex-direction: space-between;
  flex-direction: column;
  gap: 24px;
`;

export const InviteTitle = styled.h5`
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.mediumHighBlack};
  margin-bottom: 8px;
  opacity: ${props => (props.expired ? 0.6 : 1)};
`;

export const InfoGuestContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  margin: 0 12px;
`;

export const InfoGuestIcon = styled.div`
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.expired ? 0.6 : 1)};
  border-radius: 100%;
  min-height: 48px;
  min-width: 48px;
`;

export const InfoGuestContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
`;

export const Label = styled(label)`
  margin: 0;

  ${({ title, expired, theme }) => `
    font-size: ${title ? '14px' : '16px'};
    line-height: ${title ? '1.5' : '1.14'};
    letter-spacing: ${title ? '0.5px' : '0.25px'};
    color: ${
      title ? `${theme.colors.mediumHighBlack}` : `${theme.colors.highBlack}`
    };
    opacity: ${expired ? 0.6 : 1};
  `}
`;

export const Span = styled.span`
  text-align: center;
  padding: 0 16px;

  ${({ warning, theme }) => `
    font-size: ${warning ? '16px' : '14px;'};
    color: ${
      warning
        ? `${theme.colors.dangerPrimary}`
        : `${theme.colors.mediumHighBlack}`
    };
    font-weight: ${warning ? 'bold' : 'normal'};
    letter-spacing: ${warning ? '0.5px' : '0.25px'};
    line-height: ${warning ? '1.5' : '1.43'};
  `}

  & + & {
    padding-bottom: 10px;
  }
`;

export const FeedbackInvalidContainer = styled.div`
  padding: 16px 24px;
  text-align: center;
`;

export const LinkMaps = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: ${props => props.theme.colors.highBlack};
  &:hover {
    color: ${props => props.theme.colors.invitePorterSecondary};
  }
`;

export const Button = styled.button`
  background: transparent;
  color: #fff;
  text-decoration: none;
  border: none;
  outline: 0;
  &:focus {
    outline: 0;
  }
`;

export const GoogleWalletButton = styled.button`
  background-image: url(${props => i18Styles[props.lang].button});
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => i18Styles[props.lang].height};
  width: 300px;
  color: #fff;
  margin-bottom: 10px;
  text-decoration: underline;
  border: none;
  &:hover {
    color: ${darken(0.05, '#fff')};
  }
  &:focus {
    outline: 0;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.65;
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      margin: -10px;
      border: 4px solid #fff;
      border-top-color: ${props =>
        props.isPorter
          ? props.theme.colors.invitePorterPrimary
          : props.theme.colors.inviteKiperPrimary};
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }
  }
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
`;

export const CollapseHead = styled.button`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  gap: 4px;
  border: none;
  outline: 0;
  background: none;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.invitePorterSecondary};
  height: ${({ showAddress }) => (showAddress ? 0 : 'auto')};
  transition: height 0.5s ease-out;
  &:focus {
    outline: 0;
  }
`;

export const CollapseInfo = styled.span`
  display: flex;
`;

export const FacialRegisterIcon = styled.div`
  background: url(${props => props.icon}) no-repeat center center;
  height: 220px;
  width: 210px;
  margin: 30px auto;
  color: transparent;
`;

export const FacialRegisterIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;
